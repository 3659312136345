import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { ethers } from 'ethers'
import { useSelector } from 'react-redux'
import AnimatedNumber from 'animated-number-react'
import Backdrop from '@mui/material/Backdrop'
import Tooltip from '@mui/material/Tooltip'
import { AiOutlineLoading3Quarters, AiOutlineCopy } from 'react-icons/ai'
import { BiImage } from 'react-icons/bi'
import { BsShop } from 'react-icons/bs'
import { GoSync } from 'react-icons/go'
import {
    MdVerified,
    MdOutlineAccountBalanceWallet,
    MdOutlineGames,
} from 'react-icons/md'
import { FaWindowClose } from 'react-icons/fa'
import SteplineInfuse from './SteplineInfuse'
import AddNetwork from './AddNetwork'
import axios from 'axios'
import IERC1155 from '../abi/IERC1155'
/* Verify Contract */
let verifyContract = []
verifyContract.push(process.env.REACT_APP_NFT1155STAKE_CONTRACT.toLowerCase())
verifyContract.push(process.env.REACT_APP_EGGHATCH_CONTRACT.toLowerCase())

const CCOIN = process.env.REACT_APP_CCOIN
const CCSX = process.env.REACT_APP_CCSX
const BUSD = process.env.REACT_APP_BUSD

const chainID = process.env.REACT_APP_CHAIN_ID
const nftPool = process.env.REACT_APP_NFTPOOL

const ABIPOOL = require('../abi/ABIPOOL')
const IERC20 = require('../abi/IERC20')

//const NFTDATA = require('./nftdata.js')
const formatValue = (value) => `${separator(Number(value).toFixed(0))}`
function separator(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const isMetaMaskInstalled = () => {
    const { ethereum } = window
    return Boolean(ethereum && ethereum.isMetaMask)
}

function ImportToGame() {
    const [error, setError] = useState()
    const [info, setInfo] = useState()
    const [account, setAccount] = useState()
    const [state, setState] = useState(0)
    const [openBackdrop, setOpenBackdrop] = useState(false)
    //const [BUSDBalance, setBUSDBalance] = useState(0)
    const [CCOINBalance, setCCOINBalance] = useState(0)
    const [isPublic, setIsPublic] = useState(true)
    const [nfts, setNfts] = useState({})

    const [nftImg, setNftImg] = useState()
    const [nftID, setNftID] = useState()
    const [nftName, setNftName] = useState()
    const [nftOwner, setNftOwner] = useState()
    const [nftAddress, setNftAddress] = useState()
    const [contractType, setContractType] = useState()
    const [attributes, setAttributes] = useState()
    const [listID, setListID] = useState()
    const [nftAmount, setNftAmount] = useState()
    const [sellingPrice, setSellingPrice] = useState(0)
    const [sellToAddr, setSellToAddr] = useState()
    const [infAmt, setInfAmt] = useState()
    const [reLoad, setReload] = useState(true)

    const wc = useSelector((state) => state.walletConnect.value)

    let provider
    let ethereum = window.ethereum
    let tempSigner

    if (!!wc.account) {
        ethereum = wc.provider
        provider = wc.web3Provider
        tempSigner = provider.getSigner()
        ethereum.on('accountsChanged', (accounts) => setState(0))
    } else if (isMetaMaskInstalled()) {
        ethereum = window.ethereum
        provider = new ethers.providers.Web3Provider(ethereum)
        tempSigner = provider.getSigner()
        ethereum.on('accountsChanged', (accounts) => setState(0))
    }

    useEffect(() => {
        console.clear()
        if (!!wc.account || isMetaMaskInstalled()) {
            getCurrentAccount()
            getCCOINBalance()
        }

        return () => {
            provider = null
            tempSigner = null
        }
    }, [wc.account, account, state])

    useEffect(() => {
        getNFTsByWallet(account, verifyContract)
        return () => {}
    }, [account, reLoad])

    //console.log('tempSigner:', tempSigner)
    //// GET Require error from contract using catch promist ////
    async function reason(_hash) {
        let hash = _hash
        let tx = await provider.getTransaction(hash)
        if (!tx) {
            console.log('Reason : tx not found')
        } else {
            let code = await provider.call(tx, tx.blockNumber)
            return ethers.utils.toUtf8String(code.substr(138))
        }
    }

    // const contract = new ethers.Contract(MARKRT, ABIPOOL, tempSigner)
    //console.log('NTTTrunk:', NTTTrunk)

    async function getCurrentAccount() {
        const accounts = await ethereum.request({
            method: 'eth_accounts',
        })
        setAccount(accounts[0])
        return accounts[0]
    }

    //console.log('list:', list)

    /*async function getBUSDBalance() {
        try {
            const constract = new ethers.Contract(BUSD, IERC20, tempSigner)
            const balance = await constract.callStatic.balanceOf(
                tempSigner.getAddress()
            )
            setBUSDBalance(parseInt(ethers.utils.formatEther(balance)))
        } catch (err) {
            console.log('err: ', err)
        }
    } */

    async function getCCOINBalance() {
        try {
            const constract = new ethers.Contract(CCOIN, IERC20, tempSigner)
            const balance = await constract.callStatic.balanceOf(
                tempSigner.getAddress()
            )
            setCCOINBalance(parseInt(ethers.utils.formatEther(balance)))
        } catch (err) {
            console.log('err: ', err)
        }
    }

    async function getNFTsByWallet(_address, _contracts) {
        console.log('account:', _address)
        console.log('_contracts:', _contracts)
        try {
            const options = {
                method: 'GET',
                url:
                    'https://deep-index.moralis.io/api/v2/' + _address + '/nft',
                params: {
                    chain: chainID, // testnet : 0x61, mainnet 0x38
                    format: 'decimal',
                    token_addresses: _contracts,
                    normalizeMetadata: 'true',
                },
                headers: {
                    accept: 'application/json',
                    'X-API-Key':
                        'dJNQrl7A3X1aQ2vruDBqKNkrHSUKLUggQyGn8ZaNlf7OiORkdn5smlIh7awApXfq',
                },
            }
            //2hr05pidx8lH2wSpcXDAKZrKE2pVI98urjqVt4hZ6lpnTkWBtv5sjtDrwXYt8dWu
            //dJNQrl7A3X1aQ2vruDBqKNkrHSUKLUggQyGn8ZaNlf7OiORkdn5smlIh7awApXfq
            axios
                .request(options)
                .then(function (response) {
                    //console.log(response.data)
                    /*window.localStorage.setItem(
                        _address.toLowerCase(),
                        JSON.stringify(response.data)
                    )*/
                    const nftArr = response.data.result
                    nftArr.sort((a, b) => a.token_id - b.token_id)
                    console.log('nftArr:', nftArr)
                    setNfts(response.data)
                })
                .catch(function (error) {
                    console.error(error)
                })

            /*window.localStorage.setItem(
                _address.toLowerCase(),
                JSON.stringify(NFTDATA)
            ) */
        } catch (err) {
            console.log('err: ', err)
        }
    }

    async function getApproveNFT(
        _nftAddress,
        _nftID,
        _contractType,
        _nextStep
    ) {
        const contract = new ethers.Contract(_nftAddress, IERC1155, tempSigner)
        try {
            const check = await contract.callStatic.isApprovedForAll(
                account,
                nftPool
            )
            console.log('check:', check)

            if (!check) {
                const tx = await contract.setApprovalForAll(nftPool, true)
                setInfo('Waiting for block confirmation')
                const receipt = await tx.wait(1).then((tx) => {
                    setState(_nextStep)
                    setInfo('Click Next Infuse your NFT')
                })
            } else {
                setState(_nextStep)
                setInfo('Click Next Infuse your NFT')
            }
        } catch (err) {
            console.log('err: ', err)
            setState(0)
        }
    }

    async function getCCOINApprove(_amount, _marketAddress, _nextStep) {
        try {
            const constract = new ethers.Contract(CCOIN, IERC20, tempSigner)

            if (CCOINBalance >= parseInt(_amount)) {
                const checkAllowance = await constract.callStatic.allowance(
                    account,
                    _marketAddress
                )

                if (
                    parseInt(_amount) >
                    parseInt(ethers.utils.formatEther(checkAllowance))
                ) {
                    setInfo('Please Approve Your CCOIN')
                    console.log('CCOINBalance:', CCOINBalance)
                    const tx = await constract.approve(
                        _marketAddress,
                        ethers.utils.parseEther(CCOINBalance.toString())
                    )
                    setInfo('Waiting for block confirmation')
                    const receipt = await tx.wait(1).then((tx) => {
                        setState(_nextStep)
                        setInfo('List you NFT to Market')
                    })
                } else {
                    setState(_nextStep)
                    setInfo('List you NFT to Market')
                }
            } else {
                setInfo('')
                setError('Insufficient CCOIN Balance.')
                setState(5)
            }
        } catch (err) {
            console.log('err: ', err)
            setState(0)
        }
    }
    async function depositNFT(_nftAddr, _ids, _tokenAmt) {
        const contract = new ethers.Contract(nftPool, ABIPOOL, tempSigner)
        try {
            const tx = await contract.depositNFT(_nftAddr, _ids, _tokenAmt)
            setInfo('Waiting for block confirmation')
            const receipt = await tx.wait(1).then((tx) => {
                setState(16)
                setInfo('Transaction Complete')
            })
        } catch (err) {
            setState(16)
            setInfo('Transaction Complete')
            console.log('err: ', err)
        }
    }

    async function addList(
        _nftID,
        _sellingPrice,
        _nftAddr,
        _sellerAddr,
        _buyerAddr
    ) {
        const contract = new ethers.Contract(nftPool, ABIPOOL, tempSigner)

        try {
            const tx = await contract.AddList(
                parseInt(_nftID),
                ethers.utils.parseEther(_sellingPrice.toString()),
                _nftAddr,
                _sellerAddr,
                _buyerAddr
            )
            setInfo('Waiting for block confirmation')
            const receipt = await tx.wait(1).then((tx) => {
                setState(9)
                setInfo('Transaction Complete')
            })
        } catch (err) {
            setState(0)
            console.log('err: ', err)
        }
    }

    /*//////////////////////////////////////////////////////////////////*/
    function Lists({
        _nftId,
        nftAddr,
        nftName,
        amount,
        image,
        nftOwner,
        attributes,
        contractType,
    }) {
        if (
            (typeof attributes === 'object' ||
                typeof attributes === 'function') &&
            attributes !== null
        ) {
            attributes = Object.entries(attributes)

            //console.log('attributes:', attributes)
        }

        async function reSync() {
            const options = {
                method: 'GET',
                url:
                    'https://deep-index.moralis.io/api/v2/nft/' +
                    nftAddr +
                    '/' +
                    _nftId +
                    '/metadata/resync',
                params: { chain: chainID, flag: 'metadata', mode: 'sync' }, // testnet : 0x61, mainnet 0x38
                headers: {
                    accept: 'application/json',
                    'X-API-Key':
                        '2hr05pidx8lH2wSpcXDAKZrKE2pVI98urjqVt4hZ6lpnTkWBtv5sjtDrwXYt8dWu',
                },
            }

            await axios
                .request(options)
                .then(function (response) {
                    console.log(response.data)
                })
                .catch(function (error) {
                    console.error(error)
                })
        }
        return (
            <div className="col-span-1 md:transition-all duration-500 m-2 hover:scale-105 drop-shadow-xl">
                <div className="item-bg flex h-[280px] md:h-[320px] flex-col  justify-center overflow-hidden items-center rounded-xl hover:border-4 border-yellow-400">
                    <div
                        onClick={() => {
                            setInfo('NFT Detail')
                            setNftImg(image)
                            setNftID(_nftId)
                            setNftName(nftName)
                            setNftAmount(amount)
                            setNftOwner(nftOwner)
                            setNftAddress(nftAddr)
                            setAttributes(attributes)
                            setContractType(contractType)
                            setState(1)
                            setOpenBackdrop(true)
                            setListID(listID)
                        }}
                        className="relative h-[300px] overflow-hidden cursor-pointer"
                    >
                        <GoSync
                            onClick={() => reSync()}
                            className="absolute m-2 right-0 text-white/20 hover:animate-spin"
                        />
                        <span
                            className={`absolute m-2 -bottom-3 right-0 text-white/50 font-bold text-2xl z-50 ${
                                amount > 1 ? '' : 'hidden'
                            }`}
                        >
                            x{amount}
                        </span>
                        {image == null ? (
                            <BiImage
                                className="items-centers text-white/20 place-items-center mt-16"
                                size={96}
                            />
                        ) : (
                            <img
                                className="px-1 md:transition-all duration-1000 mt-3 drop-shadow-xl"
                                src={image}
                            />
                        )}
                    </div>
                    <div className="w-full bg-white/10  translate-y-4 text-gray-300 p-4 justify-center text-center md:transition-all duration-400 ">
                        <span className="flex flex-row text-yellow-500 font-bold -mt-3 items-center text-sm">
                            Crypterium Fantasy
                            {verifyContract.includes(nftAddr.toLowerCase()) ? ( // Check verify contract
                                <MdVerified className="text-green-500 " />
                            ) : null}
                        </span>
                        <span className="flex flex-row  text-white text-sm">
                            {nftName == null ? 'Undefined' : nftName}
                        </span>

                        <div className="w-full flex flex-row justify-between my-3">
                            <span className="text-xs">
                                {nftAddr.slice(0, 5) +
                                    '....' +
                                    nftAddr.slice(-4)}
                            </span>
                            <span className="text-xs">ID : {_nftId}</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    /*let NFTsArr = {}
    if (!!account) {
        if (localStorage.getItem(account.toLowerCase()) === null) {
            getNFTsByWallet(account, verifyContract)
        } else {
            const NFTsData = localStorage.getItem(account.toLowerCase())
            console.log('nfts::', nfts)
        }

        //const NFTsData = nfts
        if (NFTsData != null) {
            const NFTs = JSON.parse(NFTsData)
            NFTsArr = NFTs.result
            console.log('NFTsArr:', NFTsArr)
        } else {
            getNFTsByWallet(account, verifyContract)
        }
    }*/
    //console.log('nfts::', nfts.result)
    /*//////////////////////////////////////////////////////////////////*/
    return (
        <>
            <div className="flex flex-col w-full justifyitem-center items-center mt-20 select-none">
                <AddNetwork CCOIN={CCOIN} CCSX={CCSX} BUSD={BUSD} />

                <div className="border-0 w-full md:w-5/6 grid grid-flow-row grid-cols-2 md:grid-cols-5 gap-0 p-0 md:p-5 mt-10 rounded-xl  bg-neutral-800 bg-opacity-70 backdrop-blur-xl md:transition-all">
                    <div className="hidden md:block blur-sm absolute -top-4 md:right-4 text-3xl md:text-4xl self-center -mb-3 font-bold text-yellow-700 ">
                        M y W a l l e t
                    </div>
                    <div className="hidden md:block absolute -top-5 md:right-5 text-3xl md:text-4xl self-center -mb-3 font-bold text-white ">
                        M y W a l l e t
                    </div>
                    <div className="md:hidden -mt-5 col-span-2 text-3xl font-bold text-yellow-700 blur-sm text-center">
                        M y W a l l e t
                    </div>
                    <div className="md:hidden -mt-7 col-span-2 text-3xl font-bold text-white z-40 text-center">
                        M y W a l l e t
                    </div>
                    <div className="md:col-span-5 col-span-2 bg-black/10 drop-shadow-md rounded-lg p-3 mt-5 text-white font-bold text-xl flex flex-row justify-between items-center">
                        <div className="flex flex-row">
                            <Link to="/">
                                <span className="cursor-pointer flex flex-row justify-between items-center bg-black/30 px-2  rounded-l-lg hover:bg-[#ffae00] md:transition-all p-1">
                                    <BsShop
                                        className="items-center mx-1"
                                        size={24}
                                    />
                                    Marketplace
                                </span>
                            </Link>
                            <Link to="/MyWallet">
                                <span className="cursor-pointer flex flex-row justify-between items-center bg-black/30 px-2 pr-3  hover:bg-[#ffae00] transition-all p-1">
                                    <MdOutlineAccountBalanceWallet
                                        className="items-center mr-1"
                                        size={28}
                                    />
                                    Your NFT Wallet
                                </span>
                            </Link>
                            <Link to="/ImportToGame">
                                <span className="cursor-pointer flex flex-row justify-between items-center bg-black/70 px-2  rounded-r-lg hover:bg-[#ffae00] md:transition-all p-1">
                                    <MdOutlineGames
                                        className="items-center mx-1"
                                        size={24}
                                    />
                                    Infuse to the Game
                                </span>
                            </Link>
                        </div>

                        <span className="flex flex-row items-center text-white/90">
                            <img
                                className="scale-75 mt-1"
                                src={
                                    process.env.PUBLIC_URL +
                                    '/img/coins/ccoin.png'
                                }
                            />
                            <AnimatedNumber
                                value={CCOINBalance}
                                formatValue={formatValue}
                                duration="500"
                            />
                        </span>
                    </div>

                    {!!account ? (
                        !!nfts.result ? (
                            nfts.result.length > 0 ? (
                                nfts.result.map((x, i) => {
                                    const metaData = JSON.parse(x.metadata)
                                    return (
                                        <Lists
                                            key={i}
                                            listID={i}
                                            amount={x.amount}
                                            _nftId={x.token_id}
                                            nftOwner={x.owner_of}
                                            nftName={
                                                !!metaData
                                                    ? metaData.name
                                                    : 'Undefinded'
                                            }
                                            contractType={x.contract_type}
                                            nftAddr={x.token_address}
                                            image={
                                                !!metaData
                                                    ? metaData.image
                                                    : process.env.PUBLIC_URL +
                                                      '/img/noimag.png'
                                            }
                                            attributes={
                                                !!metaData
                                                    ? metaData.attributes
                                                    : { NA: 'No Data' }
                                            }
                                        />
                                    )
                                })
                            ) : (
                                <div className=" my-10 w-full col-span-2 md:col-span-5 flex flex-row items-center text-white animate-pulse justify-center">
                                    <div id="loadingBox">No data</div>
                                </div>
                            )
                        ) : (
                            <>
                                <div className=" my-10 w-full col-span-2 md:col-span-5 flex flex-row items-center text-white animate-pulse justify-center">
                                    <div id="loadingBox">
                                        <AiOutlineLoading3Quarters
                                            color="inherit"
                                            fontSize={20}
                                            className="mx-3 font-bold animate-spin"
                                        />
                                        Loading
                                    </div>
                                </div>
                                <div className="hidden">
                                    {setTimeout(() => {
                                        try {
                                            document.getElementById(
                                                'loadingBox'
                                            ).textContent =
                                                'Unable to load data.'
                                        } catch (e) {
                                            //console.error(e)
                                        }
                                    }, 9000)}
                                </div>
                            </>
                        )
                    ) : (
                        <div className="w-full col-span-2 md:col-span-5 flex flex-row justify-center">
                            <div className="text-white text-2xl my-20">
                                Please Connect Wallet
                            </div>
                        </div>
                    )}
                </div>

                {/*///////////////////// //////////////////////////// */}
                <Backdrop
                    sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={openBackdrop && state != 0}
                    className="flex flex-col"
                >
                    <div className="bg-gray-800 flex flex-col justify-center items-center m-4 text-center text-gray-900 text-lg p-3 rounded-lg md:w-3/5 w-full display-linebreak">
                        <div
                            onClick={() => setState(0)}
                            className={`absolute bg-black/30 w-full backdrop-blur-md backdrop-grayscale-50 z-50 items-center py-40 text-white text-3xl ${
                                !!account ? 'hidden' : null
                            }`}
                        >
                            Please Connect Wallet
                            <br />
                            <br />
                            <span className="text-sm cursor-pointer p-1 border-2 rounded-md hover:bg-black/30">
                                Close
                            </span>
                        </div>

                        <div className="w-full flex flex-row justify-between">
                            <span className="mx-3 text-white/10">#{state}</span>
                            <FaWindowClose
                                fontSize={40}
                                color="#cc0e00"
                                className={`md:transition-all duration-100 drop-shadow-lg ${
                                    state == 1 ||
                                    state == 2 ||
                                    state == 3 ||
                                    state == 5 ||
                                    state == 10 ||
                                    state == 12 ||
                                    state == 14 ||
                                    state == 16
                                        ? 'cursor-pointer hover:scale-125 '
                                        : 'grayscale opacity-10'
                                }`}
                                onClick={() => {
                                    setError('')
                                    state == 1 ||
                                    state == 2 ||
                                    state == 3 ||
                                    state == 5 ||
                                    state == 10 ||
                                    state == 12 ||
                                    state == 14 ||
                                    state == 16
                                        ? setState(0)
                                        : setState(state)
                                }}
                            />
                        </div>
                        <div className="w-full flex flex-row justify-center -mt-5">
                            <div className="py-7 text-gray-200 text-3xl font-bold">
                                {info}
                                <span className="text-rose-500 font-bold">
                                    {error}
                                </span>
                            </div>
                        </div>

                        {state == 1 ? (
                            <>
                                <div className="w-full md:w-4/5 rounded-lg drop-shadow-md flex flex-col justify-center py-7 items-center bg-black/30 ">
                                    <div className="w-11/12 flex flex-row my-1 p-2 bg-white/5 rounded-lg ">
                                        <span className="w-full flex flex-row text-gray-400 font-bold justify-center items-center hover:text-gray-100  md:transition-all">
                                            <a
                                                href={`https://bscscan.com/address/${nftAddress}`}
                                                target="_blank"
                                            >
                                                {nftAddress.slice(0, 6) +
                                                    '....' +
                                                    nftAddress.slice(-6)}
                                            </a>
                                            {verifyContract.includes(
                                                nftAddress.toLowerCase()
                                            ) ? ( // Check verify contract
                                                <MdVerified className="text-green-500 " />
                                            ) : null}
                                            <AiOutlineCopy
                                                onClick={() => {
                                                    navigator.clipboard.writeText(
                                                        nftAddress
                                                    )
                                                }}
                                                className="cursor-pointer hover:text-orange-400"
                                            />
                                        </span>
                                    </div>

                                    <span className="w-full flex md:flex-row flex-col justify-center items-center">
                                        {nftImg == null ? (
                                            <BiImage
                                                className="items-centers  text-white/20  place-items-center mx-16 mt-14"
                                                size={120}
                                            />
                                        ) : (
                                            <div className="w-1/3 rounded-xl md:w-1/2 -mx-5 mt-1 transition-all duration-1000 h-max">
                                                <img src={nftImg} />
                                            </div>
                                        )}

                                        <span className="w-11/12 md:w-1/2 mt-5 flex flex-col justify-center p-5 bg-black/30 text-gray-200 rounded-lg text-md">
                                            <span className="w-full text-center md:text-right text-4xl font-bold -mt-2 mb-2">
                                                {nftName}
                                            </span>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    ID
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftID}
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Amount
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftAmount}
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <Tooltip
                                                    title={'sellerAddress'}
                                                    placement="right"
                                                >
                                                    <span className="font-bold">
                                                        Owner
                                                    </span>
                                                </Tooltip>
                                                <span className="flex flex-row text-gray-200 items-center">
                                                    {nftOwner.slice(0, 5) +
                                                        '....' +
                                                        nftOwner.slice(-4)}

                                                    <AiOutlineCopy
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                nftAddress
                                                            )
                                                        }}
                                                        className="cursor-pointer hover:text-orange-400"
                                                    />
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Token Standard
                                                </span>

                                                <span className="flex flex-row text-gray-200 ">
                                                    {contractType}
                                                </span>
                                            </div>
                                            <span className="w-full text-left font-bold mb-1">
                                                Attributes
                                            </span>
                                            {!!attributes ? (
                                                <div className="bg-gray-400/5 drop-shadow-lg flex flex-col rounded-lg mt-0 p-2 text-left text-gray-400 text-sm">
                                                    {attributes.map((x, i) => (
                                                        <div
                                                            key={500 * i}
                                                            className="flex flex-row justify-between"
                                                        >
                                                            <span>
                                                                {x[0] !=
                                                                'Properties'
                                                                    ? x[0]
                                                                    : ''}
                                                            </span>
                                                            <span>{x[1]}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <div />
                                            )}
                                        </span>
                                    </span>
                                </div>
                                <div className="w-full md:w-4/5 flex flex-row justify-end">
                                    <div
                                        onClick={() => {
                                            setState(10)
                                            setError('')
                                            setInfo('')
                                            //getBUSDApprove(nftPrice)
                                        }}
                                        className="w-2/3 self-end flex flex-row justify-center bg-black/30 text-yellow-500 hover:bg-white/10 text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 md:transition-all shadow-lg cursor-pointer items-center"
                                    >
                                        <MdOutlineGames className="-ml-3 mr-2" />
                                        Infuse to The Game
                                    </div>
                                </div>
                            </>
                        ) : null}
                        {state == 2 ? (
                            <>
                                <div className="w-full md:w-4/5 rounded-lg drop-shadow-md flex flex-col justify-center py-7 items-center bg-black/30 ">
                                    <div className="w-11/12 flex flex-row my-1 p-2 bg-white/5 rounded-lg ">
                                        <SteplineInfuse
                                            step={0}
                                            mode={'Infuse'}
                                        />
                                    </div>
                                    <span className="w-full flex flex-row">
                                        {nftImg == null ? (
                                            <BiImage
                                                className="w-1/2 -ml-3 items-centers text-white/20 place-items-center mt-16"
                                                size={96}
                                            />
                                        ) : (
                                            <div className="w-1/3 md:w-1/2 px-1 mt-4 transition-all duration-1000 h-max">
                                                <img src={nftImg} />
                                            </div>
                                        )}

                                        <span className="w-2/3 md:w-1/2 -ml-5 mt-5 flex flex-col justify-center p-5 bg-black/30 text-gray-200 rounded-lg text-md">
                                            <span className="w-full text-center md:text-right text-4xl font-bold -mt-2 mb-2">
                                                {nftName}
                                            </span>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    ID
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftID}
                                                </span>
                                            </div>

                                            <div className="w-full flex flex-row justify-between">
                                                <Tooltip
                                                    title={'sellerAddress'}
                                                    placement="right"
                                                >
                                                    <span className="font-bold">
                                                        Owner
                                                    </span>
                                                </Tooltip>
                                                <span className="flex flex-row text-gray-200 items-center">
                                                    {nftOwner.slice(0, 5) +
                                                        '....' +
                                                        nftOwner.slice(-4)}

                                                    <AiOutlineCopy
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                nftAddress
                                                            )
                                                        }}
                                                        className="cursor-pointer hover:text-orange-400"
                                                    />
                                                </span>
                                            </div>

                                            <span className="w-full text-left font-bold mb-1 flex flex-row">
                                                Selling Price
                                                <img
                                                    className="scale-75 mt-1"
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        '/img/coins/busd.png'
                                                    }
                                                />
                                            </span>
                                            <input
                                                placeholder="BUSD"
                                                type="number"
                                                pattern="[0-9]*"
                                                min="1"
                                                step="10"
                                                id="_sellingPrice"
                                                keyboardtype="decimal-pad"
                                                autoComplete="off"
                                                className="text-right m-2 w-full active:outline-none rounded-md px-4 outline-non bg-gray-100 text-slate-600 text-3xl font-bold focus:outline-none"
                                            />

                                            <div className="w-full flex flex-row justify-between my-1">
                                                <span className="font-bold">
                                                    Sell To
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    <div
                                                        onClick={() => {
                                                            setIsPublic(true)

                                                            document.getElementById(
                                                                '_sellToAddress'
                                                            ).disabled = true
                                                        }}
                                                        className={`cursor-pointer  rounded-lg px-2 font-bold mx-1 md:transition-all ${
                                                            isPublic
                                                                ? 'bg-blue-600'
                                                                : 'bg-white/10 text-gray-500'
                                                        }`}
                                                    >
                                                        Public
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            setIsPublic(false)

                                                            document.getElementById(
                                                                '_sellToAddress'
                                                            ).disabled = false
                                                        }}
                                                        className={`cursor-pointer  rounded-lg px-2 font-bold mx-1 md:transition-all ${
                                                            !isPublic
                                                                ? 'bg-blue-600'
                                                                : 'bg-white/10 text-gray-500'
                                                        }`}
                                                    >
                                                        Private
                                                    </div>
                                                </span>
                                            </div>
                                            <input
                                                placeholder="Address"
                                                type="text"
                                                min="1"
                                                step="10"
                                                id="_sellToAddress"
                                                keyboardtype="decimal-pad"
                                                autoComplete="off"
                                                className={`text-right m-2 w-full active:outline-none md:transition-all rounded-md px-4 outline-non  text-slate-600 text-3xl font-bold focus:outline-none ${
                                                    !isPublic
                                                        ? 'bg-gray-100'
                                                        : 'bg-white/10 text-gray-500 opacity-40'
                                                }`}
                                            />
                                        </span>
                                    </span>
                                    <span className="text-white/70 mt-2 -mb-5 flex flex-row items-center text-sm">
                                        Listing Fee{' '}
                                        <img
                                            className="scale-75 ml-1"
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/img/coins/ccoin.png'
                                            }
                                        />{' '}
                                        {} / Selling Fee {}%
                                    </span>
                                </div>
                                <div className="w-full md:w-4/5 flex flex-row justify-between">
                                    <div
                                        onClick={() => {
                                            setState(1)
                                            setError('')
                                            setInfo('NFT Detail')
                                        }}
                                        className="w-1/2 self-end bg-black/30 text-gray-500 hover:bg-white/10 text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 md:transition-all shadow-lg cursor-pointer"
                                    >
                                        Back
                                    </div>
                                    <div
                                        onClick={() => {
                                            if (
                                                document.getElementById(
                                                    '_sellingPrice'
                                                ).value > 0
                                            ) {
                                                setSellingPrice(
                                                    document.getElementById(
                                                        '_sellingPrice'
                                                    ).value
                                                )

                                                if (isPublic) {
                                                    // GO NEXT
                                                    setInfo(
                                                        'Click Next to Approve your NFT'
                                                    )
                                                    setError('')
                                                    setSellToAddr(
                                                        '0x0000000000000000000000000000000000000000'
                                                    )
                                                    setState(3)
                                                } else {
                                                    if (
                                                        ethers.utils.isAddress(
                                                            document.getElementById(
                                                                '_sellToAddress'
                                                            ).value
                                                        )
                                                    ) {
                                                        //GO NEXT
                                                        setInfo(
                                                            'Click Next to Approve your NFT'
                                                        )
                                                        setError('')
                                                        setSellToAddr(
                                                            document.getElementById(
                                                                '_sellToAddress'
                                                            ).value
                                                        )
                                                        setState(3)
                                                    } else {
                                                        setInfo('')
                                                        setError(
                                                            'Invalid Sell to Address'
                                                        )
                                                    }
                                                }
                                            } else {
                                                setInfo('')
                                                setError(
                                                    'Please enter Selling Price'
                                                )
                                            }
                                        }}
                                        className="w-1/2 self-end bg-blue-600 text-gray-200 hover:bg-yellow-500  text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 md:transition-all shadow-lg cursor-pointer"
                                    >
                                        Next
                                    </div>
                                </div>
                            </>
                        ) : null}
                        {/* //////////////////////////////////////////////////////////////////////////////////// */}
                        {state == 3 ? (
                            <>
                                <div className="w-full md:w-4/5 rounded-lg drop-shadow-md flex flex-col justify-center py-7 items-center bg-black/30 ">
                                    <div className="w-11/12 flex flex-row my-1 p-2 bg-white/5 rounded-lg ">
                                        <SteplineInfuse step={1} />
                                    </div>
                                    <span className="w-full flex flex-row">
                                        {nftImg == null ? (
                                            <BiImage
                                                className="w-1/2 -ml-3 items-centers text-white/20 place-items-center mt-16"
                                                size={96}
                                            />
                                        ) : (
                                            <div className="w-1/3 md:w-1/2 px-1 mt-4 transition-all duration-1000 h-max">
                                                <img src={nftImg} />
                                            </div>
                                        )}

                                        <span className="w-2/3 md:w-1/2 -ml-5 mt-5 flex flex-col justify-center p-5 bg-black/30 text-gray-200 rounded-lg text-md">
                                            <span className="w-full text-center md:text-right text-4xl font-bold -mt-2 mb-2">
                                                {nftName}
                                            </span>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    ID
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftID}
                                                </span>
                                            </div>

                                            <div className="w-full flex flex-row justify-between">
                                                <Tooltip
                                                    title={'sellerAddress'}
                                                    placement="right"
                                                >
                                                    <span className="font-bold">
                                                        Owner
                                                    </span>
                                                </Tooltip>
                                                <span className="flex flex-row text-gray-200 items-center">
                                                    {nftOwner.slice(0, 5) +
                                                        '....' +
                                                        nftOwner.slice(-4)}

                                                    <AiOutlineCopy
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                nftAddress
                                                            )
                                                        }}
                                                        className="cursor-pointer hover:text-orange-400"
                                                    />
                                                </span>
                                            </div>

                                            <span className="w-full text-left font-bold mb-1 flex flex-row">
                                                Selling Price
                                                <img
                                                    className="scale-75 mt-1"
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        '/img/coins/busd.png'
                                                    }
                                                />
                                            </span>
                                            <span className="text-right m-2 w-full active:outline-none rounded-md px-4 outline-non  text-white text-4xl font-bold focus:outline-none">
                                                {separator(sellingPrice)}
                                            </span>

                                            <div className="w-full flex flex-row justify-between my-1">
                                                <span className="font-bold">
                                                    Sell To
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    <div
                                                        className={`rounded-lg px-2 font-bold mx-1 md:transition-all ${
                                                            isPublic
                                                                ? 'bg-blue-600'
                                                                : 'bg-white/5 text-gray-700'
                                                        }`}
                                                    >
                                                        Public
                                                    </div>
                                                    <div
                                                        className={`rounded-lg px-2 font-bold mx-1 md:transition-all ${
                                                            !isPublic
                                                                ? 'bg-blue-600'
                                                                : 'bg-white/5 text-gray-700'
                                                        }`}
                                                    >
                                                        Private
                                                    </div>
                                                </span>
                                            </div>
                                            <span
                                                placeholder="Address"
                                                type="text"
                                                min="1"
                                                step="10"
                                                id="_sellToAddress"
                                                keyboardtype="decimal-pad"
                                                autoComplete="off"
                                                className={`text-center m-2 p-2 w-full active:outline-none md:transition-all rounded-md px-4 outline-non  text-md font-bold focus:outline-none ${
                                                    !isPublic
                                                        ? 'bg-gray-100/5'
                                                        : 'bg-white/10 text-white/10 opacity-40'
                                                }`}
                                            >
                                                {sellToAddr.slice(0, 9) +
                                                    '....' +
                                                    sellToAddr.slice(-9)}
                                            </span>
                                        </span>
                                    </span>
                                    <span className="text-white/70 mt-2 -mb-5 flex flex-row items-center text-sm">
                                        Listing Fee{' '}
                                        <img
                                            className="scale-75 ml-1"
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/img/coins/ccoin.png'
                                            }
                                        />{' '}
                                        {} / Selling Fee {}%
                                    </span>
                                </div>
                                <div className="w-full md:w-4/5 flex flex-row justify-between">
                                    <div
                                        onClick={() => {
                                            setState(2)
                                            setError('')
                                            setInfo('Listing NFT for Sale')
                                        }}
                                        className="w-1/2 self-end bg-black/30 text-gray-500 hover:bg-white/10 text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 md:transition-all shadow-lg cursor-pointer"
                                    >
                                        Back
                                    </div>
                                    <div
                                        onClick={() => {
                                            setState(4)
                                            setError('')
                                            setInfo('Checking NFT Approval')
                                            getApproveNFT(
                                                nftAddress,
                                                nftID,
                                                contractType,
                                                5
                                            )
                                        }}
                                        className="w-1/2 self-end bg-blue-600 text-gray-200 hover:bg-yellow-500  text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 md:transition-all shadow-lg cursor-pointer"
                                    >
                                        Next
                                    </div>
                                </div>
                            </>
                        ) : null}

                        {state == 4 ? (
                            <>
                                <div className="w-full md:w-4/5 rounded-lg drop-shadow-md flex flex-col justify-center py-7 items-center bg-black/30 ">
                                    <div className="w-11/12 flex flex-row my-1 p-2 bg-white/5 rounded-lg ">
                                        <SteplineInfuse step={3} />
                                    </div>
                                    <span className="w-full flex flex-row opacity-50 grayscale">
                                        {nftImg == null ? (
                                            <BiImage
                                                className="w-1/2 -ml-3 items-centers text-white/20 place-items-center mt-16"
                                                size={96}
                                            />
                                        ) : (
                                            <div className="w-1/3 md:w-1/2 px-1 mt-4 transition-all duration-1000 h-max">
                                                <img src={nftImg} />
                                            </div>
                                        )}

                                        <span className="w-2/3 md:w-1/2 -ml-5 mt-5 flex flex-col justify-center p-5 bg-black/30 text-gray-200 rounded-lg text-md">
                                            <span className="w-full text-center md:text-right text-4xl font-bold -mt-2 mb-2">
                                                {nftName}
                                            </span>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    ID
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftID}
                                                </span>
                                            </div>

                                            <div className="w-full flex flex-row justify-between">
                                                <Tooltip
                                                    title={'sellerAddress'}
                                                    placement="right"
                                                >
                                                    <span className="font-bold">
                                                        Owner
                                                    </span>
                                                </Tooltip>
                                                <span className="flex flex-row text-gray-200 items-center">
                                                    {nftOwner.slice(0, 5) +
                                                        '....' +
                                                        nftOwner.slice(-4)}

                                                    <AiOutlineCopy
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                nftAddress
                                                            )
                                                        }}
                                                        className="cursor-pointer hover:text-orange-400"
                                                    />
                                                </span>
                                            </div>

                                            <span className="w-full text-left font-bold mb-1 flex flex-row">
                                                Selling Price
                                                <img
                                                    className="scale-75 mt-1"
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        '/img/coins/busd.png'
                                                    }
                                                />
                                            </span>
                                            <span className="text-right m-2 w-full active:outline-none rounded-md px-4 outline-non  text-white text-4xl font-bold focus:outline-none">
                                                {separator(sellingPrice)}
                                            </span>

                                            <div className="w-full flex flex-row justify-between my-1">
                                                <span className="font-bold">
                                                    Sell To
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    <div
                                                        className={`rounded-lg px-2 font-bold mx-1 md:transition-all ${
                                                            isPublic
                                                                ? 'bg-blue-600'
                                                                : 'bg-white/5 text-gray-700'
                                                        }`}
                                                    >
                                                        Public
                                                    </div>
                                                    <div
                                                        className={`rounded-lg px-2 font-bold mx-1 md:transition-all ${
                                                            !isPublic
                                                                ? 'bg-blue-600'
                                                                : 'bg-white/5 text-gray-700'
                                                        }`}
                                                    >
                                                        Private
                                                    </div>
                                                </span>
                                            </div>
                                            <span
                                                placeholder="Address"
                                                type="text"
                                                min="1"
                                                step="10"
                                                id="_sellToAddress"
                                                keyboardtype="decimal-pad"
                                                autoComplete="off"
                                                className={`text-center m-2 p-2 w-full active:outline-none md:transition-all rounded-md px-4 outline-non  text-md font-bold focus:outline-none ${
                                                    !isPublic
                                                        ? 'bg-gray-100/5'
                                                        : 'bg-white/10 text-white/10 opacity-40'
                                                }`}
                                            >
                                                {sellToAddr.slice(0, 9) +
                                                    '....' +
                                                    sellToAddr.slice(-9)}
                                            </span>
                                        </span>
                                    </span>
                                    <span className="text-white/70 mt-2 -mb-5 flex flex-row items-center text-sm">
                                        Listing Fee{' '}
                                        <img
                                            className="scale-75 ml-1"
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/img/coins/ccoin.png'
                                            }
                                        />{' '}
                                        {} / Selling Fee {}%
                                    </span>
                                </div>
                                <div className="w-4/5 flex flex-row justify-between">
                                    <div className="w-1/2 self-end bg-black/10 text-gray-500/30 text-3xl font-bold p-2 m-2 my-4 rounded-xl md:transition-all shadow-lg">
                                        Back
                                    </div>

                                    <div className="relative w-1/2 bg-gray-400/50 text-white p-2 m-2 pl-0 my-4 rounded-md shadow-lg items-center justify-center content-center flex flex-row">
                                        <AiOutlineLoading3Quarters
                                            color="inherit"
                                            fontSize={24}
                                            className="mx-3 font-bold animate-spin"
                                        />
                                        <span className="animate-pulse text-3xl font-bold">
                                            Waiting
                                        </span>
                                    </div>
                                </div>
                            </>
                        ) : null}

                        {state == 5 ? (
                            <>
                                <div className="w-full md:w-4/5 rounded-lg drop-shadow-md flex flex-col justify-center py-7 items-center bg-black/30 ">
                                    <div className="w-11/12 flex flex-row my-1 p-2 bg-white/5 rounded-lg ">
                                        <SteplineInfuse step={3} />
                                    </div>
                                    <span className="w-full flex flex-row opacity-50 grayscale">
                                        {nftImg == null ? (
                                            <BiImage
                                                className="w-1/2 -ml-3 items-centers text-white/20 place-items-center mt-16"
                                                size={96}
                                            />
                                        ) : (
                                            <div className="w-1/3 md:w-1/2 px-1 mt-4 transition-all duration-1000 h-max">
                                                <img src={nftImg} />
                                            </div>
                                        )}

                                        <span className="w-2/3 md:w-1/2 -ml-5 mt-5 flex flex-col justify-center p-5 bg-black/30 text-gray-200 rounded-lg text-md">
                                            <span className="w-full text-center md:text-right text-4xl font-bold -mt-2 mb-2">
                                                {nftName}
                                            </span>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    ID
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftID}
                                                </span>
                                            </div>

                                            <div className="w-full flex flex-row justify-between">
                                                <Tooltip
                                                    title={'sellerAddress'}
                                                    placement="right"
                                                >
                                                    <span className="font-bold">
                                                        Owner
                                                    </span>
                                                </Tooltip>
                                                <span className="flex flex-row text-gray-200 items-center">
                                                    {nftOwner.slice(0, 5) +
                                                        '....' +
                                                        nftOwner.slice(-4)}

                                                    <AiOutlineCopy
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                nftAddress
                                                            )
                                                        }}
                                                        className="cursor-pointer hover:text-orange-400"
                                                    />
                                                </span>
                                            </div>

                                            <span className="w-full text-left font-bold mb-1 flex flex-row">
                                                Selling Price
                                                <img
                                                    className="scale-75 mt-1"
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        '/img/coins/busd.png'
                                                    }
                                                />
                                            </span>
                                            <span className="text-right m-2 w-full active:outline-none rounded-md px-4 outline-non  text-white text-4xl font-bold focus:outline-none">
                                                {separator(sellingPrice)}
                                            </span>

                                            <div className="w-full flex flex-row justify-between my-1">
                                                <span className="font-bold">
                                                    Sell To
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    <div
                                                        className={`rounded-lg px-2 font-bold mx-1 md:transition-all ${
                                                            isPublic
                                                                ? 'bg-blue-600'
                                                                : 'bg-white/5 text-gray-700'
                                                        }`}
                                                    >
                                                        Public
                                                    </div>
                                                    <div
                                                        className={`rounded-lg px-2 font-bold mx-1 md:transition-all ${
                                                            !isPublic
                                                                ? 'bg-blue-600'
                                                                : 'bg-white/5 text-gray-700'
                                                        }`}
                                                    >
                                                        Private
                                                    </div>
                                                </span>
                                            </div>
                                            <span
                                                placeholder="Address"
                                                type="text"
                                                min="1"
                                                step="10"
                                                id="_sellToAddress"
                                                keyboardtype="decimal-pad"
                                                autoComplete="off"
                                                className={`text-center m-2 p-2 w-full active:outline-none md:transition-all rounded-md px-4 outline-non  text-md font-bold focus:outline-none ${
                                                    !isPublic
                                                        ? 'bg-gray-100/5'
                                                        : 'bg-white/10 text-white/10 opacity-40'
                                                }`}
                                            >
                                                {sellToAddr.slice(0, 9) +
                                                    '....' +
                                                    sellToAddr.slice(-9)}
                                            </span>
                                        </span>
                                    </span>
                                    <span className="text-white/70 mt-2 -mb-5 flex flex-row items-center text-sm">
                                        Listing Fee{' '}
                                        <img
                                            className="scale-75 ml-1"
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/img/coins/ccoin.png'
                                            }
                                        />{' '}
                                        {} / Selling Fee {}%
                                    </span>
                                </div>
                                <div className="w-full md:w-4/5 flex flex-row justify-between">
                                    <div
                                        onClick={() => {
                                            setState(2)
                                            setError('')
                                            setInfo('Listing NFT for Sale')
                                        }}
                                        className="w-1/2 self-end bg-black/30 text-gray-500 hover:bg-white/10 text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 md:transition-all shadow-lg cursor-pointer"
                                    >
                                        Back
                                    </div>
                                    <div
                                        onClick={() => {
                                            setError('')
                                            setInfo('Checking CCOIN Approval')
                                            getCCOINApprove(50, nftPool, 7)
                                            setState(6)
                                        }}
                                        className="w-1/2 self-end bg-blue-600 text-gray-200 hover:bg-yellow-500  text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 md:transition-all shadow-lg cursor-pointer"
                                    >
                                        Next
                                    </div>
                                </div>
                            </>
                        ) : null}
                        {state == 6 ? (
                            <>
                                <div className="w-full md:w-4/5 rounded-lg drop-shadow-md flex flex-col justify-center py-7 items-center bg-black/30 ">
                                    <div className="w-11/12 flex flex-row my-1 p-2 bg-white/5 rounded-lg ">
                                        <SteplineInfuse step={4} />
                                    </div>
                                    <span className="w-full flex flex-row opacity-50 grayscale">
                                        {nftImg == null ? (
                                            <BiImage
                                                className="w-1/2 -ml-3 items-centers text-white/20 place-items-center mt-16"
                                                size={96}
                                            />
                                        ) : (
                                            <div className="w-1/3 md:w-1/2 px-1 mt-4 transition-all duration-1000 h-max">
                                                <img src={nftImg} />
                                            </div>
                                        )}

                                        <span className="w-2/3 md:w-1/2 -ml-5 mt-5 flex flex-col justify-center p-5 bg-black/30 text-gray-200 rounded-lg text-md">
                                            <span className="w-full text-center md:text-right text-4xl font-bold -mt-2 mb-2">
                                                {nftName}
                                            </span>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    ID
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftID}
                                                </span>
                                            </div>

                                            <div className="w-full flex flex-row justify-between">
                                                <Tooltip
                                                    title={'sellerAddress'}
                                                    placement="right"
                                                >
                                                    <span className="font-bold">
                                                        Owner
                                                    </span>
                                                </Tooltip>
                                                <span className="flex flex-row text-gray-200 items-center">
                                                    {nftOwner.slice(0, 5) +
                                                        '....' +
                                                        nftOwner.slice(-4)}

                                                    <AiOutlineCopy
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                nftAddress
                                                            )
                                                        }}
                                                        className="cursor-pointer hover:text-orange-400"
                                                    />
                                                </span>
                                            </div>

                                            <span className="w-full text-left font-bold mb-1 flex flex-row">
                                                Selling Price
                                                <img
                                                    className="scale-75 mt-1"
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        '/img/coins/busd.png'
                                                    }
                                                />
                                            </span>
                                            <span className="text-right m-2 w-full active:outline-none rounded-md px-4 outline-non  text-white text-4xl font-bold focus:outline-none">
                                                {separator(sellingPrice)}
                                            </span>

                                            <div className="w-full flex flex-row justify-between my-1">
                                                <span className="font-bold">
                                                    Sell To
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    <div
                                                        className={`rounded-lg px-2 font-bold mx-1 md:transition-all ${
                                                            isPublic
                                                                ? 'bg-blue-600'
                                                                : 'bg-white/5 text-gray-700'
                                                        }`}
                                                    >
                                                        Public
                                                    </div>
                                                    <div
                                                        className={`rounded-lg px-2 font-bold mx-1 md:transition-all ${
                                                            !isPublic
                                                                ? 'bg-blue-600'
                                                                : 'bg-white/5 text-gray-700'
                                                        }`}
                                                    >
                                                        Private
                                                    </div>
                                                </span>
                                            </div>
                                            <span
                                                placeholder="Address"
                                                type="text"
                                                min="1"
                                                step="10"
                                                id="_sellToAddress"
                                                keyboardtype="decimal-pad"
                                                autoComplete="off"
                                                className={`text-center m-2 p-2 w-full active:outline-none md:transition-all rounded-md px-4 outline-non  text-md font-bold focus:outline-none ${
                                                    !isPublic
                                                        ? 'bg-gray-100/5'
                                                        : 'bg-white/10 text-white/10 opacity-40'
                                                }`}
                                            >
                                                {sellToAddr.slice(0, 9) +
                                                    '....' +
                                                    sellToAddr.slice(-9)}
                                            </span>
                                        </span>
                                    </span>
                                    <span className="text-white/70 mt-2 -mb-5 flex flex-row items-center text-sm">
                                        Listing Fee{' '}
                                        <img
                                            className="scale-75 ml-1"
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/img/coins/ccoin.png'
                                            }
                                        />{' '}
                                        {} / Selling Fee {}%
                                    </span>
                                </div>
                                <div className="w-full md:w-4/5 flex flex-row justify-between">
                                    <div className="w-1/2 self-end bg-black/10 text-gray-500/30 text-3xl font-bold p-2 m-2 my-4 rounded-xl md:transition-all shadow-lg">
                                        Back
                                    </div>

                                    <div className="relative w-1/2 bg-gray-400/50 text-white p-2 m-2 pl-0 my-4 rounded-md shadow-lg items-center justify-center content-center flex flex-row">
                                        <AiOutlineLoading3Quarters
                                            color="inherit"
                                            fontSize={24}
                                            className="mx-3 font-bold animate-spin"
                                        />
                                        <span className="animate-pulse text-3xl font-bold">
                                            Waiting
                                        </span>
                                    </div>
                                </div>
                            </>
                        ) : null}

                        {state == 7 ? (
                            <>
                                <div className="w-full md:w-4/5 rounded-lg drop-shadow-md flex flex-col justify-center py-7 items-center bg-black/30 ">
                                    <div className="w-11/12 flex flex-row my-1 p-2 bg-white/5 rounded-lg ">
                                        <SteplineInfuse step={4} />
                                    </div>
                                    <span className="w-full flex flex-row opacity-50 grayscale">
                                        {nftImg == null ? (
                                            <BiImage
                                                className="w-1/2 -ml-3 items-centers text-white/20 place-items-center mt-16"
                                                size={96}
                                            />
                                        ) : (
                                            <div className="w-1/3 md:w-1/2 px-1 mt-4 transition-all duration-1000 h-max">
                                                <img src={nftImg} />
                                            </div>
                                        )}

                                        <span className="w-2/3 md:w-1/2 -ml-5 mt-5 flex flex-col justify-center p-5 bg-black/30 text-gray-200 rounded-lg text-md">
                                            <span className="w-full text-center md:text-right text-4xl font-bold -mt-2 mb-2">
                                                {nftName}
                                            </span>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    ID
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftID}
                                                </span>
                                            </div>

                                            <div className="w-full flex flex-row justify-between">
                                                <Tooltip
                                                    title={'sellerAddress'}
                                                    placement="right"
                                                >
                                                    <span className="font-bold">
                                                        Owner
                                                    </span>
                                                </Tooltip>
                                                <span className="flex flex-row text-gray-200 items-center">
                                                    {nftOwner.slice(0, 5) +
                                                        '....' +
                                                        nftOwner.slice(-4)}

                                                    <AiOutlineCopy
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                nftAddress
                                                            )
                                                        }}
                                                        className="cursor-pointer hover:text-orange-400"
                                                    />
                                                </span>
                                            </div>

                                            <span className="w-full text-left font-bold mb-1 flex flex-row">
                                                Selling Price
                                                <img
                                                    className="scale-75 mt-1"
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        '/img/coins/busd.png'
                                                    }
                                                />
                                            </span>
                                            <span className="text-right m-2 w-full active:outline-none rounded-md px-4 outline-non  text-white text-4xl font-bold focus:outline-none">
                                                {separator(sellingPrice)}
                                            </span>

                                            <div className="w-full flex flex-row justify-between my-1">
                                                <span className="font-bold">
                                                    Sell To
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    <div
                                                        className={`rounded-lg px-2 font-bold mx-1 md:transition-all ${
                                                            isPublic
                                                                ? 'bg-blue-600'
                                                                : 'bg-white/5 text-gray-700'
                                                        }`}
                                                    >
                                                        Public
                                                    </div>
                                                    <div
                                                        className={`rounded-lg px-2 font-bold mx-1 md:transition-all ${
                                                            !isPublic
                                                                ? 'bg-blue-600'
                                                                : 'bg-white/5 text-gray-700'
                                                        }`}
                                                    >
                                                        Private
                                                    </div>
                                                </span>
                                            </div>
                                            <span
                                                placeholder="Address"
                                                type="text"
                                                min="1"
                                                step="10"
                                                id="_sellToAddress"
                                                keyboardtype="decimal-pad"
                                                autoComplete="off"
                                                className={`text-center m-2 p-2 w-full active:outline-none md:transition-all rounded-md px-4 outline-non  text-md font-bold focus:outline-none ${
                                                    !isPublic
                                                        ? 'bg-gray-100/5'
                                                        : 'bg-white/10 text-white/10 opacity-40'
                                                }`}
                                            >
                                                {sellToAddr.slice(0, 9) +
                                                    '....' +
                                                    sellToAddr.slice(-9)}
                                            </span>
                                        </span>
                                    </span>
                                    <span className="text-white/70 mt-2 -mb-5 flex flex-row items-center text-sm">
                                        Listing Fee{' '}
                                        <img
                                            className="scale-75 ml-1"
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/img/coins/ccoin.png'
                                            }
                                        />{' '}
                                        {} / Selling Fee {}%
                                    </span>
                                </div>
                                <div className="w-full md:w-4/5 flex flex-row justify-between">
                                    <div
                                        onClick={() => {
                                            setState(2)
                                            setError('')
                                            setInfo('Listing NFT for Sale')
                                        }}
                                        className="w-1/2 self-end bg-black/30 text-gray-500 hover:bg-white/10 text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 md:transition-all shadow-lg cursor-pointer"
                                    >
                                        Back
                                    </div>
                                    <div
                                        onClick={() => {
                                            setError('')
                                            setInfo(
                                                'Please Confirm Metamask to List your NFT'
                                            )

                                            addList(
                                                nftID,
                                                sellingPrice,
                                                nftAddress,
                                                account,
                                                sellToAddr
                                            )
                                            setState(8)
                                        }}
                                        className="w-1/2 self-end bg-blue-600 text-gray-200 hover:bg-yellow-500  text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 md:transition-all shadow-lg cursor-pointer"
                                    >
                                        Next
                                    </div>
                                </div>
                            </>
                        ) : null}
                        {state == 8 ? (
                            <>
                                <div className="w-full md:w-4/5 rounded-lg drop-shadow-md flex flex-col justify-center py-7 items-center bg-black/30 ">
                                    <div className="w-11/12 flex flex-row my-1 p-2 bg-white/5 rounded-lg ">
                                        <SteplineInfuse step={5} />
                                    </div>
                                    <span className="w-full flex flex-row opacity-50 grayscale">
                                        {nftImg == null ? (
                                            <BiImage
                                                className="w-1/2 -ml-3 items-centers text-white/20 place-items-center mt-16"
                                                size={96}
                                            />
                                        ) : (
                                            <div className="w-1/3 md:w-1/2 px-1 mt-4 transition-all duration-1000 h-max">
                                                <img src={nftImg} />
                                            </div>
                                        )}

                                        <span className="w-2/3 md:w-1/2 -ml-5 mt-5 flex flex-col justify-center p-5 bg-black/30 text-gray-200 rounded-lg text-md">
                                            <span className="w-full text-center md:text-right text-4xl font-bold -mt-2 mb-2">
                                                {nftName}
                                            </span>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    ID
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftID}
                                                </span>
                                            </div>

                                            <div className="w-full flex flex-row justify-between">
                                                <Tooltip
                                                    title={'sellerAddress'}
                                                    placement="right"
                                                >
                                                    <span className="font-bold">
                                                        Owner
                                                    </span>
                                                </Tooltip>
                                                <span className="flex flex-row text-gray-200 items-center">
                                                    {nftOwner.slice(0, 5) +
                                                        '....' +
                                                        nftOwner.slice(-4)}

                                                    <AiOutlineCopy
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                nftAddress
                                                            )
                                                        }}
                                                        className="cursor-pointer hover:text-orange-400"
                                                    />
                                                </span>
                                            </div>

                                            <span className="w-full text-left font-bold mb-1 flex flex-row">
                                                Selling Price
                                                <img
                                                    className="scale-75 mt-1"
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        '/img/coins/busd.png'
                                                    }
                                                />
                                            </span>
                                            <span className="text-right m-2 w-full active:outline-none rounded-md px-4 outline-non  text-white text-4xl font-bold focus:outline-none">
                                                {separator(sellingPrice)}
                                            </span>

                                            <div className="w-full flex flex-row justify-between my-1">
                                                <span className="font-bold">
                                                    Sell To
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    <div
                                                        className={`rounded-lg px-2 font-bold mx-1 md:transition-all ${
                                                            isPublic
                                                                ? 'bg-blue-600'
                                                                : 'bg-white/5 text-gray-700'
                                                        }`}
                                                    >
                                                        Public
                                                    </div>
                                                    <div
                                                        className={`rounded-lg px-2 font-bold mx-1 md:transition-all ${
                                                            !isPublic
                                                                ? 'bg-blue-600'
                                                                : 'bg-white/5 text-gray-700'
                                                        }`}
                                                    >
                                                        Private
                                                    </div>
                                                </span>
                                            </div>
                                            <span
                                                placeholder="Address"
                                                type="text"
                                                min="1"
                                                step="10"
                                                id="_sellToAddress"
                                                keyboardtype="decimal-pad"
                                                autoComplete="off"
                                                className={`text-center m-2 p-2 w-full active:outline-none md:transition-all rounded-md px-4 outline-non  text-md font-bold focus:outline-none ${
                                                    !isPublic
                                                        ? 'bg-gray-100/5'
                                                        : 'bg-white/10 text-white/10 opacity-40'
                                                }`}
                                            >
                                                {sellToAddr.slice(0, 9) +
                                                    '....' +
                                                    sellToAddr.slice(-9)}
                                            </span>
                                        </span>
                                    </span>
                                    <span className="text-white/70 mt-2 -mb-5 flex flex-row items-center text-sm">
                                        Listing Fee{' '}
                                        <img
                                            className="scale-75 ml-1"
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/img/coins/ccoin.png'
                                            }
                                        />{' '}
                                        {} / Selling Fee {}%
                                    </span>
                                </div>
                                <div className="w-full md:w-4/5 flex flex-row justify-between">
                                    <div className="w-1/2 self-end bg-black/10 text-gray-500/30 text-3xl font-bold p-2 m-2 my-4 rounded-xl md:transition-all shadow-lg">
                                        Back
                                    </div>

                                    <div className="relative w-1/2 bg-gray-400/50 text-white p-2 m-2 pl-0 my-4 rounded-md shadow-lg items-center justify-center content-center flex flex-row">
                                        <AiOutlineLoading3Quarters
                                            color="inherit"
                                            fontSize={24}
                                            className="mx-3 font-bold animate-spin"
                                        />
                                        <span className="animate-pulse text-3xl font-bold">
                                            Waiting
                                        </span>
                                    </div>
                                </div>
                            </>
                        ) : null}
                        {state == 9 ? (
                            <>
                                <div className="w-full md:w-4/5 rounded-lg drop-shadow-md flex flex-col justify-center py-7 items-center bg-black/30 ">
                                    <div className="w-11/12 flex flex-row my-1 p-2 bg-white/5 rounded-lg ">
                                        <SteplineInfuse step={6} />
                                    </div>
                                    <span className="w-full flex flex-row ">
                                        {nftImg == null ? (
                                            <BiImage
                                                className="w-1/2 -ml-3 items-centers text-white/20 place-items-center mt-16"
                                                size={96}
                                            />
                                        ) : (
                                            <div className="w-1/3 md:w-1/2 px-1 mt-4 transition-all duration-1000 h-max">
                                                <img src={nftImg} />
                                            </div>
                                        )}

                                        <span className="w-2/3 md:w-1/2 -ml-5 mt-5 flex flex-col justify-center p-5 bg-black/30 text-gray-200 rounded-lg text-md">
                                            <span className="w-full text-center md:text-right text-4xl font-bold -mt-2 mb-2">
                                                {nftName}
                                            </span>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    ID
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftID}
                                                </span>
                                            </div>

                                            <div className="w-full flex flex-row justify-between">
                                                <Tooltip
                                                    title={'sellerAddress'}
                                                    placement="right"
                                                >
                                                    <span className="font-bold">
                                                        Owner
                                                    </span>
                                                </Tooltip>
                                                <span className="flex flex-row text-gray-200 items-center">
                                                    {nftOwner.slice(0, 5) +
                                                        '....' +
                                                        nftOwner.slice(-4)}

                                                    <AiOutlineCopy
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                nftAddress
                                                            )
                                                        }}
                                                        className="cursor-pointer hover:text-orange-400"
                                                    />
                                                </span>
                                            </div>

                                            <span className="w-full text-left font-bold mb-1 flex flex-row">
                                                Selling Price
                                                <img
                                                    className="scale-75 mt-1"
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        '/img/coins/busd.png'
                                                    }
                                                />
                                            </span>
                                            <span className="text-right m-2 w-full active:outline-none rounded-md px-4 outline-non  text-white text-4xl font-bold focus:outline-none">
                                                {separator(sellingPrice)}
                                            </span>

                                            <div className="w-full flex flex-row justify-between my-1">
                                                <span className="font-bold">
                                                    Sell To
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    <div
                                                        className={`rounded-lg px-2 font-bold mx-1 md:transition-all ${
                                                            isPublic
                                                                ? 'bg-blue-600'
                                                                : 'bg-white/5 text-gray-700'
                                                        }`}
                                                    >
                                                        Public
                                                    </div>
                                                    <div
                                                        className={`rounded-lg px-2 font-bold mx-1 md:transition-all ${
                                                            !isPublic
                                                                ? 'bg-blue-600'
                                                                : 'bg-white/5 text-gray-700'
                                                        }`}
                                                    >
                                                        Private
                                                    </div>
                                                </span>
                                            </div>
                                            <span
                                                placeholder="Address"
                                                type="text"
                                                min="1"
                                                step="10"
                                                id="_sellToAddress"
                                                keyboardtype="decimal-pad"
                                                autoComplete="off"
                                                className={`text-center m-2 p-2 w-full active:outline-none md:transition-all rounded-md px-4 outline-non  text-md font-bold focus:outline-none ${
                                                    !isPublic
                                                        ? 'bg-gray-100/5'
                                                        : 'bg-white/10 text-white/10 opacity-40'
                                                }`}
                                            >
                                                {sellToAddr.slice(0, 9) +
                                                    '....' +
                                                    sellToAddr.slice(-9)}
                                            </span>
                                        </span>
                                    </span>
                                    <span className="text-white/70 mt-2 -mb-5 flex flex-row items-center text-sm">
                                        Listing Fee{' '}
                                        <img
                                            className="scale-75 ml-1"
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/img/coins/ccoin.png'
                                            }
                                        />{' '}
                                        {} / Selling Fee {}%
                                    </span>
                                </div>
                                <div className="w-4/5 flex flex-row justify-between">
                                    <div className="w-1/2 self-end bg-black/10 text-gray-500/30 text-3xl font-bold p-2 m-2 my-4 rounded-xl md:transition-all shadow-lg">
                                        Back
                                    </div>

                                    <div
                                        onClick={() => {
                                            setInfo('')
                                            setError('')
                                            setState(0)
                                        }}
                                        className="w-1/2 self-end bg-blue-600 text-gray-200 hover:bg-yellow-500 text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 md:transition-all shadow-lg cursor-pointer"
                                    >
                                        Done
                                    </div>
                                </div>
                            </>
                        ) : null}
                        {/* /////////////////////////////////////// T R A N S F E R ///////////////////////////////////////////// */}
                        {state == 10 ? (
                            <>
                                <div className="md:w-4/5 w-full rounded-3xl drop-shadow-md flex flex-col justify-center py-5 bg-black/30 ">
                                    <div className="w-11/12 mx-6 flex flex-row my-1 p-2 bg-white/5 rounded-lg ">
                                        <SteplineInfuse
                                            step={0}
                                            mode={'Infuse'}
                                        />
                                    </div>
                                    <span className="w-full flex flex-row scale-90">
                                        {nftImg == null ? (
                                            <BiImage
                                                className="w-1/2 -ml-3 items-centers text-white/20 place-items-center mt-16"
                                                size={96}
                                            />
                                        ) : (
                                            <div className="w-1/3 md:w-1/2 px-1 mt-4 transition-all duration-1000 h-max">
                                                <img src={nftImg} />
                                            </div>
                                        )}

                                        <span className="w-2/3 md:w-1/2 h-min -ml-5 mt-5 flex flex-col justify-center p-5 bg-black/30 text-gray-200 rounded-lg text-md">
                                            <span className="w-full text-center md:text-right text-4xl font-bold -mt-2 mb-2">
                                                {nftName}
                                            </span>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    ID
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftID}
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Amount
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftAmount}
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <Tooltip
                                                    title={'sellerAddress'}
                                                    placement="right"
                                                >
                                                    <span className="font-bold">
                                                        Owner
                                                    </span>
                                                </Tooltip>
                                                <span className="flex flex-row text-gray-200 items-center">
                                                    {nftOwner.slice(0, 5) +
                                                        '....' +
                                                        nftOwner.slice(-4)}

                                                    <AiOutlineCopy
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                nftAddress
                                                            )
                                                        }}
                                                        className="cursor-pointer hover:text-orange-400"
                                                    />
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Token Standard
                                                </span>

                                                <span className="flex flex-row text-gray-200 ">
                                                    {contractType}
                                                </span>
                                            </div>
                                        </span>
                                    </span>
                                    <div className="w-11/12 mx-6 flex flex-col my-0 p-3 bg-white/5 rounded-lg ">
                                        <div className="flex flex-row justify-center my-1 font-bold text-gray-100 text-left text-xl">
                                            Infuse Amount
                                        </div>
                                        <div className="text-center w-full md:transition-all rounded-md px-4 py-2  text-slate-600 text-xl font-bold ">
                                            <span className="w-full flex flex-row text-gray-400 font-bold justify-center items-center hover:text-gray-100  md:transition-all">
                                                <input
                                                    placeholder={0}
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    min="1"
                                                    step="1"
                                                    max={nftAmount}
                                                    id="_infAmt"
                                                    keyboardtype="decimal-pad"
                                                    autoComplete="off"
                                                    className="text-right m-2 w-1/4 active:outline-none rounded-md px-4 outline-non bg-white text-slate-600 text-3xl font-bold focus:outline-none"
                                                />{' '}
                                                / {nftAmount}
                                            </span>
                                            {}
                                        </div>
                                    </div>
                                </div>

                                <div className="w-full md:w-4/5 flex flex-row justify-between">
                                    <div
                                        onClick={() => {
                                            setState(1)
                                            setError('')
                                            setInfo('NFT Detail')
                                        }}
                                        className="w-1/2 self-end bg-black/30 text-gray-500 hover:bg-white/10 text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 md:transition-all shadow-lg cursor-pointer"
                                    >
                                        Back
                                    </div>
                                    <div
                                        onClick={() => {
                                            //GO NEXT

                                            if (
                                                document.getElementById(
                                                    '_infAmt'
                                                ).value <= nftAmount &&
                                                document.getElementById(
                                                    '_infAmt'
                                                ).value >= 1
                                            ) {
                                                setInfo(
                                                    'Confirm metamask to Approve your NFT'
                                                )
                                                setError('')
                                                setInfAmt(
                                                    document.getElementById(
                                                        '_infAmt'
                                                    ).value
                                                )
                                                setState(11)
                                                getApproveNFT(
                                                    nftAddress,
                                                    nftID,
                                                    contractType,
                                                    12
                                                )
                                            } else {
                                                setError('NFT Amount Error!')
                                            }
                                        }}
                                        className="w-1/2 self-end bg-blue-600 text-gray-200 hover:bg-yellow-500  text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 md:transition-all shadow-lg cursor-pointer"
                                    >
                                        Next
                                    </div>
                                </div>
                            </>
                        ) : null}
                        {/* //////////////////////////////////////////////////////////////////////////////////// */}

                        {state == 11 ? (
                            <>
                                <div className="md:w-4/5 w-full rounded-3xl drop-shadow-md flex flex-col justify-center py-5 bg-black/30 ">
                                    <div className="w-11/12 mx-6 flex flex-row my-1 p-2 bg-white/5 rounded-lg ">
                                        <SteplineInfuse
                                            step={1}
                                            mode={'Infuse'}
                                        />
                                    </div>
                                    <span className="w-full flex flex-row scale-90">
                                        {nftImg == null ? (
                                            <BiImage
                                                className="w-1/2 -ml-3 items-centers text-white/20 place-items-center mt-16"
                                                size={96}
                                            />
                                        ) : (
                                            <div className="w-1/3 md:w-1/2 px-1 mt-4 transition-all duration-1000 h-max">
                                                <img src={nftImg} />
                                            </div>
                                        )}

                                        <span className="w-2/3 md:w-1/2 h-min -ml-5 mt-5 flex flex-col justify-center p-5 bg-black/30 text-gray-200 rounded-lg text-md">
                                            <span className="w-full text-center md:text-right text-4xl font-bold -mt-2 mb-2">
                                                {nftName}
                                            </span>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    ID
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftID}
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Amount
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftAmount}
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <Tooltip
                                                    title={'sellerAddress'}
                                                    placement="right"
                                                >
                                                    <span className="font-bold">
                                                        Owner
                                                    </span>
                                                </Tooltip>
                                                <span className="flex flex-row text-gray-200 items-center">
                                                    {nftOwner.slice(0, 5) +
                                                        '....' +
                                                        nftOwner.slice(-4)}

                                                    <AiOutlineCopy
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                nftAddress
                                                            )
                                                        }}
                                                        className="cursor-pointer hover:text-orange-400"
                                                    />
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Token Standard
                                                </span>

                                                <span className="flex flex-row text-gray-200 ">
                                                    {contractType}
                                                </span>
                                            </div>
                                        </span>
                                    </span>
                                    <div className="w-11/12 mx-6 flex flex-col my-0 p-3 bg-white/5 rounded-lg ">
                                        <div className="flex flex-row justify-center my-1 font-bold text-gray-100 text-left text-xl">
                                            Infuse Amount
                                        </div>
                                        <div className="text-center w-full md:transition-all rounded-md px-4 py-2  text-slate-600 text-xl font-bold ">
                                            <span className="w-full flex flex-row text-gray-400 font-bold justify-center items-center hover:text-gray-100  md:transition-all">
                                                {infAmt}
                                            </span>
                                            {}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full md:w-4/5 flex flex-row justify-end">
                                    <div className="w-4/5 flex flex-row justify-between">
                                        <div className="w-1/2 self-end bg-black/10 text-gray-500/30 text-3xl font-bold p-2 m-2 my-4 rounded-xl md:transition-all shadow-lg">
                                            Back
                                        </div>

                                        <div className="relative w-1/2 bg-gray-400/50 text-white p-2 m-2 pl-0 my-4 rounded-md shadow-lg items-center justify-center content-center flex flex-row">
                                            <AiOutlineLoading3Quarters
                                                color="inherit"
                                                fontSize={24}
                                                className="mx-3 font-bold animate-spin"
                                            />
                                            <span className="animate-pulse text-3xl font-bold">
                                                Waiting
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : null}
                        {/* //////////////////////////////////////////////////////////////////////////////////// */}
                        {state == 12 ? (
                            <>
                                <div className="md:w-4/5 w-full rounded-3xl drop-shadow-md flex flex-col justify-center py-7 bg-black/30 ">
                                    <div className="w-11/12 mx-6 flex flex-row my-1 p-2 bg-white/5 rounded-lg ">
                                        <SteplineInfuse
                                            step={2}
                                            mode={'Infuse'}
                                        />
                                    </div>
                                    <span className="w-full flex flex-row scale-90">
                                        {nftImg == null ? (
                                            <BiImage
                                                className="w-1/2 -ml-3 items-centers text-white/20 place-items-center mt-16"
                                                size={96}
                                            />
                                        ) : (
                                            <div className="w-1/3 md:w-1/2 px-1 mt-4 transition-all duration-1000 h-max">
                                                <img src={nftImg} />
                                            </div>
                                        )}

                                        <span className="w-2/3 md:w-1/2 h-min -ml-5 mt-5 flex flex-col justify-center p-5 bg-black/30 text-gray-200 rounded-lg text-md">
                                            <span className="w-full text-center md:text-right text-4xl font-bold -mt-2 mb-2">
                                                {nftName}
                                            </span>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    ID
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftID}
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Amount
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftAmount}
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <Tooltip
                                                    title={'sellerAddress'}
                                                    placement="right"
                                                >
                                                    <span className="font-bold">
                                                        Owner
                                                    </span>
                                                </Tooltip>
                                                <span className="flex flex-row text-gray-200 items-center">
                                                    {nftOwner.slice(0, 5) +
                                                        '....' +
                                                        nftOwner.slice(-4)}

                                                    <AiOutlineCopy
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                nftAddress
                                                            )
                                                        }}
                                                        className="cursor-pointer hover:text-orange-400"
                                                    />
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Token Standard
                                                </span>

                                                <span className="flex flex-row text-gray-200 ">
                                                    {contractType}
                                                </span>
                                            </div>
                                        </span>
                                    </span>
                                    <div className="w-11/12 mx-6 flex flex-col my-0 p-3 bg-white/5 rounded-lg ">
                                        <div className="flex flex-row justify-center my-1 font-bold text-gray-100 text-left text-xl">
                                            Infuse
                                            <span className="text-red-400 mx-1">
                                                {infAmt}
                                            </span>
                                            NFT to Contract
                                        </div>
                                        <div className="text-center w-full md:transition-all rounded-md px-4 py-2  text-slate-600 text-xl font-bold ">
                                            <span className="w-full flex flex-row text-gray-400 font-bold justify-center items-center hover:text-gray-100  md:transition-all">
                                                <a
                                                    href={`https://bscscan.com/address/${nftPool}`}
                                                    target="_blank"
                                                >
                                                    {nftPool.slice(0, 6) +
                                                        '....' +
                                                        nftPool.slice(-6)}
                                                </a>
                                                {verifyContract.includes(
                                                    nftPool.toLowerCase()
                                                ) ? ( // Check verify contract
                                                    <MdVerified className="text-green-500 " />
                                                ) : null}
                                                <AiOutlineCopy
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(
                                                            nftPool
                                                        )
                                                    }}
                                                    className="cursor-pointer hover:text-orange-400"
                                                />
                                            </span>
                                            {}
                                        </div>
                                    </div>
                                </div>

                                <div className="w-full md:w-4/5 flex flex-row justify-between">
                                    <div
                                        onClick={() => {
                                            setState(10)
                                            setError('')
                                            setInfo('NFT Detail')
                                        }}
                                        className="w-1/2 self-end bg-black/30 text-gray-500 hover:bg-white/10 text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 md:transition-all shadow-lg cursor-pointer"
                                    >
                                        Back
                                    </div>
                                    <div
                                        onClick={() => {
                                            setState(13)
                                            setInfo(
                                                'Confirm Metamask to Infuse Your NFT'
                                            )
                                            depositNFT(
                                                nftAddress,
                                                [nftID],
                                                [infAmt]
                                            )
                                        }}
                                        className="w-1/2 self-end bg-blue-600 text-gray-200 hover:bg-yellow-500  text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 md:transition-all shadow-lg cursor-pointer"
                                    >
                                        Next
                                    </div>
                                </div>
                            </>
                        ) : null}

                        {/* //////////////////////////////////////////////////////////////////////////////////// */}
                        {state == 13 ? (
                            <>
                                <div className="md:w-4/5 w-full rounded-3xl drop-shadow-md flex flex-col justify-center py-7 bg-black/30 ">
                                    <div className="w-11/12 mx-6 flex flex-row my-1 p-2 bg-white/5 rounded-lg ">
                                        <SteplineInfuse
                                            step={3}
                                            mode={'Infuse'}
                                        />
                                    </div>
                                    <span className="w-full flex flex-row scale-90">
                                        {nftImg == null ? (
                                            <BiImage
                                                className="w-1/2 -ml-3 items-centers text-white/20 place-items-center mt-16"
                                                size={96}
                                            />
                                        ) : (
                                            <div className="w-1/3 md:w-1/2 px-1 mt-4 transition-all duration-1000 h-max">
                                                <img src={nftImg} />
                                            </div>
                                        )}

                                        <span className="w-2/3 md:w-1/2 h-min -ml-5 mt-5 flex flex-col justify-center p-5 bg-black/30 text-gray-200 rounded-lg text-md">
                                            <span className="w-full text-center md:text-right text-4xl font-bold -mt-2 mb-2">
                                                {nftName}
                                            </span>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    ID
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftID}
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Amount
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftAmount}
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <Tooltip
                                                    title={'sellerAddress'}
                                                    placement="right"
                                                >
                                                    <span className="font-bold">
                                                        Owner
                                                    </span>
                                                </Tooltip>
                                                <span className="flex flex-row text-gray-200 items-center">
                                                    {nftOwner.slice(0, 5) +
                                                        '....' +
                                                        nftOwner.slice(-4)}

                                                    <AiOutlineCopy
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                nftAddress
                                                            )
                                                        }}
                                                        className="cursor-pointer hover:text-orange-400"
                                                    />
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Token Standard
                                                </span>

                                                <span className="flex flex-row text-gray-200 ">
                                                    {contractType}
                                                </span>
                                            </div>
                                        </span>
                                    </span>

                                    <div className="w-11/12 mx-6 flex flex-col my-0 p-3 bg-white/5 rounded-lg ">
                                        <div className="flex flex-row justify-center my-1 font-bold text-gray-100 text-left text-xl">
                                            Infuse
                                            <span className="text-red-400 mx-1">
                                                {infAmt}
                                            </span>
                                            NFT to Contract
                                        </div>
                                        <div className="text-center w-full md:transition-all rounded-md px-4 py-2  text-slate-600 text-xl font-bold ">
                                            <span className="w-full flex flex-row text-gray-400 font-bold justify-center items-center hover:text-gray-100  md:transition-all">
                                                <a
                                                    href={`https://bscscan.com/address/${nftPool}`}
                                                    target="_blank"
                                                >
                                                    {nftPool.slice(0, 6) +
                                                        '....' +
                                                        nftPool.slice(-6)}
                                                </a>
                                                {verifyContract.includes(
                                                    nftPool.toLowerCase()
                                                ) ? ( // Check verify contract
                                                    <MdVerified className="text-green-500 " />
                                                ) : null}
                                                <AiOutlineCopy
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(
                                                            nftPool
                                                        )
                                                    }}
                                                    className="cursor-pointer hover:text-orange-400"
                                                />
                                            </span>
                                            {}
                                        </div>
                                    </div>
                                </div>

                                <div className="w-full md:w-4/5 flex flex-row justify-end">
                                    <div className="w-4/5 flex flex-row justify-between">
                                        <div className="w-1/2 self-end bg-black/10 text-gray-500/30 text-3xl font-bold p-2 m-2 my-4 rounded-xl md:transition-all shadow-lg">
                                            Back
                                        </div>

                                        <div className="relative w-1/2 bg-gray-400/50 text-white p-2 m-2 pl-0 my-4 rounded-md shadow-lg items-center justify-center content-center flex flex-row">
                                            <AiOutlineLoading3Quarters
                                                color="inherit"
                                                fontSize={24}
                                                className="mx-3 font-bold animate-spin"
                                            />
                                            <span className="animate-pulse text-3xl font-bold">
                                                Waiting
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : null}
                        {/* //////////////////////////////////////////////////////////////////////////////////// */}
                        {state == 14 ? (
                            <>
                                <div className="md:w-4/5 w-full rounded-3xl drop-shadow-md flex flex-col justify-center py-7 bg-black/30 ">
                                    <div className="w-11/12 mx-6 flex flex-row my-1 p-2 bg-white/5 rounded-lg ">
                                        <SteplineInfuse
                                            step={4}
                                            mode={'Infuse'}
                                        />
                                    </div>
                                    <span className="w-full flex flex-row scale-90">
                                        {nftImg == null ? (
                                            <BiImage
                                                className="w-1/2 -ml-3 items-centers text-white/20 place-items-center mt-16"
                                                size={96}
                                            />
                                        ) : (
                                            <div className="w-1/3 md:w-1/2 px-1 mt-4 transition-all duration-1000 h-max">
                                                <img src={nftImg} />
                                            </div>
                                        )}

                                        <span className="w-2/3 md:w-1/2 h-min -ml-5 mt-5 flex flex-col justify-center p-5 bg-black/30 text-gray-200 rounded-lg text-md">
                                            <span className="w-full text-center md:text-right text-4xl font-bold -mt-2 mb-2">
                                                {nftName}
                                            </span>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    ID
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftID}
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Amount
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftAmount}
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <Tooltip
                                                    title={'sellerAddress'}
                                                    placement="right"
                                                >
                                                    <span className="font-bold">
                                                        Owner
                                                    </span>
                                                </Tooltip>
                                                <span className="flex flex-row text-gray-200 items-center">
                                                    {nftOwner.slice(0, 5) +
                                                        '....' +
                                                        nftOwner.slice(-4)}

                                                    <AiOutlineCopy
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                nftAddress
                                                            )
                                                        }}
                                                        className="cursor-pointer hover:text-orange-400"
                                                    />
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Token Standard
                                                </span>

                                                <span className="flex flex-row text-gray-200 ">
                                                    {contractType}
                                                </span>
                                            </div>
                                        </span>
                                    </span>
                                    <div className="w-11/12 mx-6 flex flex-col my-1 p-3 bg-white/5 rounded-lg ">
                                        <div className="flex flex-row justify-start my-1 font-bold text-gray-100 text-left text-2xl">
                                            Send To
                                        </div>
                                        <div className="text-center w-full bg-black/30 md:transition-all rounded-md px-4 py-2 outline-non  text-slate-300 text-xl font-bold">
                                            {sellToAddr}
                                        </div>
                                        <span className="text-white/70 mt-1 -mb-2 flex flex-row items-center justify-center text-sm">
                                            Transfer Fee
                                            <img
                                                className="scale-75 ml-1"
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/img/coins/ccoin.png'
                                                }
                                            />
                                            {}
                                        </span>
                                    </div>
                                </div>

                                <div className="w-full md:w-4/5 flex flex-row justify-between">
                                    <div
                                        onClick={() => {
                                            setState(1)
                                            setError('')
                                            setInfo('NFT Detail')
                                        }}
                                        className="w-1/2 self-end bg-black/30 text-gray-500 hover:bg-white/10 text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 md:transition-all shadow-lg cursor-pointer"
                                    >
                                        Back
                                    </div>
                                    <div
                                        onClick={() => {
                                            setState(15)
                                            setInfo(
                                                'Confirm Metamask to Send your NFT'
                                            )
                                        }}
                                        className="w-1/2 self-end bg-blue-600 text-gray-200 hover:bg-yellow-500  text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 md:transition-all shadow-lg cursor-pointer"
                                    >
                                        Send
                                    </div>
                                </div>
                            </>
                        ) : null}
                        {/* //////////////////////////////////////////////////////////////////////////////////// */}

                        {state == 15 ? (
                            <>
                                <div className="md:w-4/5 w-full rounded-3xl drop-shadow-md flex flex-col justify-center py-7 bg-black/30 ">
                                    <div className="w-11/12 mx-6 flex flex-row my-1 p-2 bg-white/5 rounded-lg ">
                                        <SteplineInfuse
                                            step={5}
                                            mode={'Infuse'}
                                        />
                                    </div>
                                    <span className="w-full flex flex-row scale-90">
                                        {nftImg == null ? (
                                            <BiImage
                                                className="w-1/2 -ml-3 items-centers text-white/20 place-items-center mt-16"
                                                size={96}
                                            />
                                        ) : (
                                            <div className="w-1/3 md:w-1/2 px-1 mt-4 transition-all duration-1000 h-max">
                                                <img src={nftImg} />
                                            </div>
                                        )}

                                        <span className="w-2/3 md:w-1/2 h-min -ml-5 mt-5 flex flex-col justify-center p-5 bg-black/30 text-gray-200 rounded-lg text-md">
                                            <span className="w-full text-center md:text-right text-4xl font-bold -mt-2 mb-2">
                                                {nftName}
                                            </span>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    ID
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftID}
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Amount
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftAmount}
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <Tooltip
                                                    title={'sellerAddress'}
                                                    placement="right"
                                                >
                                                    <span className="font-bold">
                                                        Owner
                                                    </span>
                                                </Tooltip>
                                                <span className="flex flex-row text-gray-200 items-center">
                                                    {nftOwner.slice(0, 5) +
                                                        '....' +
                                                        nftOwner.slice(-4)}

                                                    <AiOutlineCopy
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                nftAddress
                                                            )
                                                        }}
                                                        className="cursor-pointer hover:text-orange-400"
                                                    />
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Token Standard
                                                </span>

                                                <span className="flex flex-row text-gray-200 ">
                                                    {contractType}
                                                </span>
                                            </div>
                                        </span>
                                    </span>
                                    <div className="w-11/12 mx-6 flex flex-col my-1 p-3 bg-white/5 rounded-lg ">
                                        <div className="flex flex-row justify-start my-1 font-bold text-gray-100 text-left text-2xl">
                                            Send To
                                        </div>
                                        <div className="text-center w-full bg-black/30 md:transition-all rounded-md px-4 py-2 outline-non  text-slate-300 text-xl font-bold">
                                            {sellToAddr}
                                        </div>
                                        <span className="text-white/70 mt-1 -mb-2 flex flex-row items-center justify-center text-sm">
                                            Transfer Fee
                                            <img
                                                className="scale-75 ml-1"
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/img/coins/ccoin.png'
                                                }
                                            />
                                            {}
                                        </span>
                                    </div>
                                </div>
                                <div className="w-full md:w-4/5 flex flex-row justify-end">
                                    <div className="w-4/5 flex flex-row justify-between">
                                        <div className="w-1/2 self-end bg-black/10 text-gray-500/30 text-3xl font-bold p-2 m-2 my-4 rounded-xl md:transition-all shadow-lg">
                                            Back
                                        </div>

                                        <div className="relative w-1/2 bg-gray-400/50 text-white p-2 m-2 pl-0 my-4 rounded-md shadow-lg items-center justify-center content-center flex flex-row">
                                            <AiOutlineLoading3Quarters
                                                color="inherit"
                                                fontSize={24}
                                                className="mx-3 font-bold animate-spin"
                                            />
                                            <span className="animate-pulse text-3xl font-bold">
                                                Waiting
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : null}
                        {/* //////////////////////////////////////////////////////////////////////////////////// */}
                        {state == 16 ? (
                            <>
                                <div className="md:w-4/5 w-full rounded-3xl drop-shadow-md flex flex-col justify-center py-7 bg-black/30 ">
                                    <div className="w-11/12 mx-6 flex flex-row my-1 p-2 bg-white/5 rounded-lg ">
                                        <SteplineInfuse
                                            step={6}
                                            mode={'Infuse'}
                                        />
                                    </div>
                                    <span className="w-full flex flex-row scale-90">
                                        {nftImg == null ? (
                                            <BiImage
                                                className="w-1/2 -ml-3 items-centers text-white/20 place-items-center mt-16"
                                                size={96}
                                            />
                                        ) : (
                                            <div className="w-1/3 md:w-1/2 px-1 mt-4 transition-all duration-1000 h-max">
                                                <img src={nftImg} />
                                            </div>
                                        )}

                                        <span className="w-2/3 md:w-1/2 h-min -ml-5 mt-5 flex flex-col justify-center p-5 bg-black/30 text-gray-200 rounded-lg text-md">
                                            <span className="w-full text-center md:text-right text-4xl font-bold -mt-2 mb-2">
                                                {nftName}
                                            </span>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    ID
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftID}
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Amount
                                                </span>
                                                <span className="flex flex-row text-gray-200">
                                                    {nftAmount}
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <Tooltip
                                                    title={'sellerAddress'}
                                                    placement="right"
                                                >
                                                    <span className="font-bold">
                                                        Owner
                                                    </span>
                                                </Tooltip>
                                                <span className="flex flex-row text-gray-200 items-center">
                                                    {nftOwner.slice(0, 5) +
                                                        '....' +
                                                        nftOwner.slice(-4)}

                                                    <AiOutlineCopy
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                nftAddress
                                                            )
                                                        }}
                                                        className="cursor-pointer hover:text-orange-400"
                                                    />
                                                </span>
                                            </div>
                                            <div className="w-full flex flex-row justify-between">
                                                <span className="font-bold">
                                                    Token Standard
                                                </span>

                                                <span className="flex flex-row text-gray-200 ">
                                                    {contractType}
                                                </span>
                                            </div>
                                        </span>
                                    </span>
                                    <div className="w-11/12 mx-6 flex flex-col my-0 p-3 bg-white/5 rounded-lg ">
                                        <div className="flex flex-row justify-center my-1 font-bold text-gray-100 text-left text-xl">
                                            Infuse
                                            <span className="text-red-400 mx-1">
                                                {infAmt}
                                            </span>
                                            NFT to Contract
                                        </div>
                                        <div className="text-center w-full md:transition-all rounded-md px-4 py-2  text-slate-600 text-xl font-bold ">
                                            <span className="w-full flex flex-row text-gray-400 font-bold justify-center items-center hover:text-gray-100  md:transition-all">
                                                <a
                                                    href={`https://bscscan.com/address/${nftPool}`}
                                                    target="_blank"
                                                >
                                                    {nftPool.slice(0, 6) +
                                                        '....' +
                                                        nftPool.slice(-6)}
                                                </a>
                                                {verifyContract.includes(
                                                    nftPool.toLowerCase()
                                                ) ? ( // Check verify contract
                                                    <MdVerified className="text-green-500 " />
                                                ) : null}
                                                <AiOutlineCopy
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(
                                                            nftPool
                                                        )
                                                    }}
                                                    className="cursor-pointer hover:text-orange-400"
                                                />
                                            </span>
                                            {}
                                        </div>
                                    </div>
                                </div>

                                <div className="w-full md:w-4/5 flex flex-row justify-between">
                                    <div className="w-1/2 self-end bg-black/10 text-gray-500/30 text-3xl font-bold p-2 m-2 my-4 rounded-xl md:transition-all shadow-lg">
                                        Back
                                    </div>

                                    <div
                                        onClick={() => {
                                            setInfo('')
                                            setError('')
                                            setState(0)
                                            setReload(!reLoad)
                                        }}
                                        className="w-1/2 self-end bg-blue-600 text-gray-200 hover:bg-yellow-500 text-3xl font-bold p-2 m-2 my-4 rounded-xl hover:scale-105 md:transition-all shadow-lg cursor-pointer"
                                    >
                                        Done
                                    </div>
                                </div>
                            </>
                        ) : null}

                        <br />
                    </div>
                    <br />
                </Backdrop>

                {/*///////////////////// THE PRIZE //////////////////////////// */}
            </div>
        </>
    )
}

export default ImportToGame
